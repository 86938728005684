.sidebar{
  width: 300px;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: white;
  padding: 0 25px;
  position: fixed;
  left: 0;
  z-index: 100;
  ul{
    padding: 0;
    li{
      list-style: none;
      padding: 14px 9px ;
      &.active{
        background: #BCEA4780;
        border-radius: 8px;
        a{
          color: black;
        }
      }
      a{
        display: flex;
        align-items: center;
        text-decoration: none;
        gap: 16px;
        color: #9197B3;
        font-size: 14px;
        position: relative;
        .right{
          position: absolute;
          right: 0;

        }
      }
    }
  }
}