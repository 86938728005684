.container{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 70%);
  z-index: 100;
  .reportMore{
    width: 407px;
    height: 532px;
    background: white;
    box-shadow: 0px 4px 84px 0px #FFFFFF40;
    padding: 20px 30px;
    border-radius: 24px;
    .up{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left{
        h1{
          font-size: 22px;
        }
        span{
          font-size: 16px;
          padding: 4px 12px;
          background: #DEF5A3;
          border-radius: 4px;
        }
      }
      .right{
        li{
          list-style: none;
          cursor: pointer;
          img{
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    .info{
      margin-top: 36px;
      ul{
        padding: 0;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 24px;
        li{
          list-style: none;
          font-size: 16px;
          span{
            margin-left: 26px;
            font-size: 14px;
            padding: 6px 12px;
            background: #DEF5A3;
            border-radius: 4px;
          }
        }
      }
    }
  }
}