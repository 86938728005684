.container{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: black, $alpha: .7);
  z-index: 100;

  .add{
    min-width: 450px;
    height: 632px;
    background: white;
    padding: 40px;
    border-radius: 40px;
    .up{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left{
        h1{
          margin: 0;
          font-size: 22px;
          font-family: 'Gilroy Semi-bold';
        }
        p{
          margin: 0;
          margin-top: 6px;
          padding: 4px 12px;
          background: #DEF5A3;
          border-radius: 4px;
          font-size: 16px;
        }
      }
      .right{
        li{
          list-style: none;
          cursor: pointer;
          img{
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    .main{
      width: 300px;
      display: flex;
      flex-direction: column;
      gap: 0px;
      margin-top: 16px;
      div{
        display: flex;
        align-items: center;
        justify-content: space-between;
        p{
          justify-content: flex-start;
        }
        &:nth-child(5){
          justify-content: flex-start;
        }
        &:nth-child(4){
          p{
            &:nth-child(2){
              padding: 6px 12px;
              border-radius: 4px;
              background: #DEF5A3;
              border: 0;
              cursor: pointer;
            }
          }
        }
        input{
          width: 200px;
          height: 32px;
          padding-left: 12px;
          border: 1px solid #B4B4B4;
          border-radius: 6px;
          outline: none;
        }
      }
    }
    .sessions{
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 8px;
      height: 220px;
      margin-top: 26px;
      div{
        display: flex;
        align-items: center;
        gap: 14px;
        button{
          width: 30px;
          height: 30px;
          font-size: 14px;
          background: #DBDBDB;
          border: 0;
          border-radius: 4px;
          cursor: pointer;
          &.active{
            background: #DEF5A3;
          }
          &.check{
            width: 70%;
            padding: 6px 12px;
            background: #DEF5A3;
            color: black;
          }
          &.frozen{
            width: 70%;
            padding: 6px 12px;
            background: #EA6635;
            border: 1px solid #EA6635;
            color: white;
          }
          &.freeze{
            width: 70%;
            padding: 6px 12px;
            background: none;
            border: 1px solid #EA6635;
            color: #EA6635;
          }
        }
        p{
          color: #B4B4B4;
          font-size: 14px;
          margin: 0;
        }
      }
    }
    .btns{
      display: flex;
      align-items: center;
      gap: 12px;
      margin-top: 36px;
      button{
        padding: 6px 12px;
        border-radius: 4px;
        background: #DBDBDB;
        border: 0;
        color: black;
        &:nth-child(1){
          background: #DEF5A3;
        }
        &:nth-child(3){
          background: #EA6635;
          color: white;
        }
      }
    }
  }
}