.container{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: black, $alpha: .7);
  z-index: 100;

  .add{
    width: 520px;
    height: 408px;
    background: white;
    padding: 40px;
    border-radius: 40px;
    position: relative;
    .up{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left{
        h1{
          margin: 0;
          font-size: 22px;
          font-family: 'Gilroy Semi-bold';
        }
        p{
          margin: 0;
          margin-top: 6px;
          padding: 4px 12px;
          background: #DEF5A3;
          border-radius: 4px;
          font-size: 16px;
        }
      }
      .right{
        li{
          list-style: none;
          cursor: pointer;
          img{
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    .main{
      width: 360px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 16px;
      div{
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:nth-child(4),
        &:nth-child(5){
          justify-content: flex-start;
        }
        &:nth-child(4){
          p{
            margin-right: 20px;
          }
          button{
            margin-right: 10px;
            padding: 6px 12px;
            border-radius: 4px;
            background: #DBDBDB;
            border: 0;
            cursor: pointer;
            &.active{
              background: #DEF5A3;
              color: black;
            }
          }
        }
        &:nth-child(3){
          display: flex;
          align-items: center;
          gap: 12px;
          position: absolute;
          bottom: 40px;
          button{
            padding: 6px 12px;
            border-radius: 4px;
            background: #DBDBDB;
            border: 0;
            color: black;
            &:nth-child(1){
              background: #DEF5A3;
            }
          }
        }
        input{
          width: 200px;
          height: 32px;
          padding-left: 12px;
          border: 1px solid #B4B4B4;
          border-radius: 6px;
          outline: none;
        }
        select{
          width: 220px;
          height: 32px;
          padding-left: 12px;
          margin-left: 10px;
          outline: none;
          cursor: pointer;
        }
        ul{
          width: 220px;
          display: flex;
          align-items: center;
          gap: 12px;
          span{
            padding: 6px 12px;
            border-radius: 6px;
            background: #DBDBDB;
            font-size: 14px;
            cursor: pointer;
            &.active{
              background: #DEF5A3;
            }
          }
        }
      }
    }
  }
}