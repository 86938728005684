.container{
  width: 90%;
  height: 100vh;
  padding: 62px 40px;
  .nav{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    .navlist{
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 14px;
      div{
        display: flex;
        align-items: center;
        gap: 14px;
        button{
          padding: 10px;
          color: black;
          border: 0;
          border-radius: 8px;
          background: none;
          font-size: 22px;
          cursor: pointer;
          &.active{
            background: #DEF5A3;
          }
        }
      }
      .months{
        button{
          font-size: 16px;
          padding: 4px 14px;
          color: #BCBCBC;
          &.active{
            color: black;
            background: #DEF5A3;
          }
        }
      }
      .period{
        display: flex;
        align-items: center;
        gap: 16px;
        span{
          padding: 4px 12px;
          background-color: #DEF5A3;
          border-radius: 4px;
        }
        p{
          color: #BCBCBC;
          font-size: 16px;
          cursor: pointer;
        }
      }
    }
    .tracking{
      width: 1000px;
      display: flex;
      background: white;
      border-radius: 30px;
      padding: 30px 50px;
      justify-content: space-between;
      gap: 20px;
      div{
        width: 400px;
        display: flex;
        align-items: center;
        &:nth-child(1){
          border-right: 1px solid #F0F0F0;
        }
        &:nth-child(2){
          border-right: 1px solid #F0F0F0;
        }
        .icon{
          width: 42px;
          height: 42px;
          padding: 20px;
          background: linear-gradient(201.18deg, #D3FFE7 3.14%, #EFFFF6 86.04%);
          border-radius: 50%;
          img{
            width: 42px;
            height: 42px;
          }
        }
        ul{
          padding: 0;
          margin: 0;
          margin-left: 20px;
          li{
            list-style: none;
            color: #ACACAC;
          }
          h1{
            margin: 6px 0;
          }
          p{
            margin: 0;
            display: flex;
            align-items: center;
            gap: 3px;
            font-family: 'Gilroy Regular';
            
            span{
              display: flex;
              align-items: center;
              gap: 3px;
              font-family: 'Gilroy Semi-bold';
            }
          }
        }
      }
      .line{
        width: 2px;
        height: 80%;
        background: #F0F0F0;
      }
    }
  }
  .report{
    width: 90%;
    height: 60vh;
    background: white;
    margin-top: 40px;
    border-radius: 30px;
    padding: 30px 40px;
    table{
      tr{
        position: relative;
        height: 60px;
        &::before{
          content: '';
          width: 100%;
          height: 1px;
          background: #B5B7C0;
          position: absolute;
          left: 0;
          bottom: 0;
        }
        th{
          font-family: 'Gilroy Regular';
          color: #B5B7C0;
          text-align: left;
          padding-right: 40px;
          &:nth-child(5){
            padding-right: 0;
          }
          &.name{ 
            padding-right: 80px;
            padding-left: 30px;
          }
          div{
            display: flex;
            align-items: center;
            gap: 220px;
            .btns{
              display: flex;
              align-items: center;
              gap: 12px;
              margin: 0;
              padding: 0;
              button{
                padding: 4px 12px;
                border-radius: 4px;
                background: #DBDBDB;
                border: 1px solid #DBDBDB;
                &.active{
                  background: #DEF5A3;
                  border: 1px solid #DEF5A3;
                  color: black;
                }
              }
            }
          }
        }
        td{
          font-family: 'Gilroy Regular';
          font-weight: 800;
          font-size: 14px;
          &.freeze{
            background: #FFFB26;
          }
          &.view{
            cursor: pointer;
          }
          &.add{
            padding-left: 35px;
            button{
              width: 80px;
              height: 30px;
              background: #DEF5A3;
              font-size: 14px;  
              border-radius: 4px;
              border: 0;
              cursor: pointer;
            }
          }
          span{
            border-radius: 4px;
            background: #DEF5A3;
            width: 82px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            
            &.summa{
              margin-left: 15px;
            }
            &.cards{
              margin-left: 20px;
            }
            &.period{
              width: 165px;
            }
          } 
          p{
            width: 40px;
            height: 26px;
            background: #DEF5A3;
            color: black;
            border-radius: 4px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          
          div{
            width: 100%;
            display: flex;
            align-items: center;
            gap: 8px;
            li{
              list-style: none;
              cursor: pointer;
              margin-left: 6px;
            }
            button{
              width: 30px;
              height: 30px;
              border: 1px solid #DBDBDB;
              background: #DBDBDB;
              color: white;
              border-radius: 4px;
              cursor: pointer;
              &.active{
                background: #DEF5A3;
                border: 1px solid #DEF5A3;
                color: black;
              }
              &.frozen{
                border: 1px solid #EA6635;
                color: white;
                background: #EA6635;
              }
            }
          }
        }
      }
    }
  }
}